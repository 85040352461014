import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { StrictMode, useState, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";

import { ToastProvider } from "@/Components/Toast";

import App from "@/Pages/App";
import Context from "@/Pages/Context";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: true,
            retry: false,
            retryDelay: 5000,
            retryOnMount: true,
            staleTime: 60 * 1000,
        },
        mutations: {
            retry: false,
            retryDelay: 5000,
        },
    },
});

const AppProvider = ({
    children,
}: {
    readonly children?: ReactNode;
}): JSX.Element => {
    const [maintenanceMode, setMaintenanceMode] = useState(false);

    const context = useMemo(
        () => ({
            maintenanceMode,
            setMaintenanceMode,
        }),
        [maintenanceMode]
    );

    return (
        <Context.Provider value={context}>
            <App />
            {children}
        </Context.Provider>
    );
};

const Root = ({ children }: { readonly children?: ReactNode }): JSX.Element => (
    <StrictMode>
        <IntlProvider locale="en">
            <ToastProvider parent={document.getElementById("app")!}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <AppProvider>{children}</AppProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </ToastProvider>
        </IntlProvider>
    </StrictMode>
);

export default Root;
