import { useEffect } from "react";

import { useGoogleTagManager } from "@/Support/Hooks/useGoogleTagManager";
import { useSegment } from "@/Support/Hooks/useSegment";
import { useMe } from "@/Support/Requests/useMe";

import { Consent as ConsentComponent } from "@/Components/Consent";

export const Consent = (): JSX.Element => {
    const { isSuccess: isLoggedIn } = useMe();

    const { invoke } = useSegment();

    const { setupGTMLayer } = useGoogleTagManager();

    useEffect(() => {
        if (import.meta.env.VITE_SEGMENT_ANALYTICS_ENABLED !== "true") return;

        if (isLoggedIn) {
            setupGTMLayer();
        }
        invoke();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isLoggedIn]);

    return <ConsentComponent />;
};
