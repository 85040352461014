import styled, { keyframes } from "styled-components";

import SpinnerIcon from "@/Components/Icons/SpinnerIcon.svg?react";

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const SpinLoader = styled(SpinnerIcon)`
    animation: ${spin} 1s linear infinite;
`;
