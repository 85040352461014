import { css } from "styled-components";

export const focusOutline = css`
    box-shadow: var(--focus-shadow);
    outline: 0;
`;

const focusInset = css`
    box-shadow: inset var(--focus-shadow);
    outline: 0;
`;

export const defaultFocusOutlineMixin = css`
    &:focus-visible {
        ${focusOutline}
    }
`;

export const defaultFocusInsetMixin = css`
    &:focus-visible {
        ${focusInset}
    }
`;

export const defaultRoundedFocusOutlineMixin = css`
    &:focus-visible {
        border-radius: 8px;
        ${focusOutline}
    }
`;
