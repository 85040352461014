import type { MeResponse } from "../Requests/useMe";
import { useMe } from "../Requests/useMe";

/** Returns current workspace using useMe hook. As we don't handle multiple workspaces for now, it uses the first of the list. */
export const useCurrentWorkspace = ():
    | MeResponse["workspaces"]["items"][0]
    | null => {
    const query = useMe();

    return query.data ? query.data.workspaces.items[0] : null;
};
