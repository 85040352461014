import type {
    AnchorHTMLAttributes,
    ComponentPropsWithoutRef,
    ForwardedRef,
    ForwardRefExoticComponent,
    ReactNode,
    RefAttributes,
} from "react";
import { forwardRef, useEffect } from "react";
import {
    Link as ReactRouterLink,
    NavLink as ReactRouterNavLink,
    useMatch,
} from "react-router-dom";
import type { FlattenSimpleInterpolation } from "styled-components";
import styled, { css } from "styled-components";

import { useInternalRef } from "@/Support/Hooks/useInternalRef";
import { useCustomURL } from "@/Support/Hooks/useNavigate";

import type {
    ButtonBaseProps,
    ButtonKind,
    ButtonStyleProps,
} from "@/Components/Button";
import {
    actionStyle,
    buttonStyle,
    darkStyle,
    destructiveStyle,
    ghostReverseStyle,
    ghostStyle,
    primaryStyle,
    secondaryReverseStyle,
    secondaryStyle,
    successStyle,
    useButtonContent,
    warningStyle,
} from "@/Components/Button";

export type LinkKind =
    | "action"
    | "default"
    | "destructive"
    | "ghost"
    | "reverse";

export const defaultLinkKind: LinkKind = "default";

export const styleLink = css<{
    $kind: LinkKind;
    $disabled: boolean;
}>`
    align-items: center;
    column-gap: 8px;
    display: inline-flex;
    font-size: inherit;
    line-height: inherit;
    transition: color 0.2s;
    width: fit-content;
    ${({ $kind, $disabled }): FlattenSimpleInterpolation | false =>
        $kind === "default" &&
        css`
            text-decoration: underline;
            ${!$disabled &&
            css`
                color: var(--color-neutral-800);
            `}
        `}
    ${({ $kind, $disabled }): FlattenSimpleInterpolation | false =>
        $kind === "action" &&
        css`
            font-weight: 500;
            ${!$disabled &&
            css`
                color: var(--color-blue-500);
            `}
        `}
    ${({ $kind, $disabled }): FlattenSimpleInterpolation | false =>
        $kind === "destructive" &&
        css`
            font-weight: 500;
            ${!$disabled &&
            css`
                color: var(--color-destructive-500);
            `}
        `}
    ${({ $kind, $disabled }): FlattenSimpleInterpolation | false =>
        $kind === "reverse" &&
        css`
            font-weight: 500;
            text-decoration: underline;
            ${$disabled
                ? css`
                      opacity: 0.4;
                  `
                : css`
                      color: var(--color-revert);
                  `}
        `}
    ${({ $kind, $disabled }): FlattenSimpleInterpolation =>
        $disabled
            ? css`
                  cursor: not-allowed;
                  ${$kind !== "reverse" &&
                  css`
                      color: var(--color-neutral-400);
                  `}
              `
            : css`
                  cursor: pointer;

                  &:focus-visible {
                      border-radius: 2px;
                      box-shadow: 0 0 0 1px var(--color-blue-200);
                      outline: 0;
                  }

                  ${$kind === "default" &&
                  css`
                      &:hover,
                      &:active {
                          color: var(--color-neutral-700);
                      }
                  `}
                  ${$kind === "action" &&
                  css`
                      &:hover {
                          color: var(--color-blue-600);
                      }

                      &:active {
                          color: var(--color-blue-700);
                      }
                  `}
                ${$kind === "destructive" &&
                  css`
                      &:hover {
                          color: var(--color-destructive-600);
                      }

                      &:active {
                          color: var(--color-destructive-700);
                      }
                  `}
                ${$kind === "reverse" &&
                  css`
                      &:hover {
                          color: var(--color-neutral-200);
                      }

                      &:active {
                          color: var(--color-neutral-400);
                      }
                  `}
              `}
`;

type NativeLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    to?: never;
};

const Anchor = styled.a<NativeLinkProps>``;

type ReactLinkProps = ComponentPropsWithoutRef<typeof ReactRouterLink> & {
    href?: never;
};

type ReactNavLinkProps = ComponentPropsWithoutRef<typeof ReactRouterLink> & {
    readonly href?: never;
    readonly customActiveNavPath?: string;
};

const ReactNavLink = forwardRef<HTMLAnchorElement, ReactNavLinkProps>(
    ({ customActiveNavPath, ...otherProps }, ref) => {
        if (customActiveNavPath) {
            return <ReactRouterLink {...otherProps} ref={ref} />;
        }

        return <ReactRouterNavLink {...otherProps} ref={ref} />;
    },
);

ReactNavLink.displayName = "ReactNavLink";

type LinkElementOnlyProps = {
    readonly disabled?: boolean;
    readonly enableActiveNav?: boolean;
    readonly customActiveNavPath?: string;
};

const SharedLink = forwardRef<
    HTMLAnchorElement | HTMLSpanElement,
    LinkElementOnlyProps &
        (NativeLinkProps | ReactLinkProps | ReactNavLinkProps)
>(
    (
        {
            href,
            disabled = false,
            onClick,
            to,
            enableActiveNav = false,
            customActiveNavPath,
            className,
            ...otherProps
        },
        ref,
    ) => {
        const getTo = useCustomURL();

        const target = to ? getTo(to) : undefined;

        const isMatching =
            useMatch(
                customActiveNavPath
                    ? customActiveNavPath
                    : target?.pathname || "",
            ) !== null;

        const props = {
            onClick: disabled ? undefined : onClick,
            className:
                enableActiveNav && isMatching
                    ? `active ${className ?? ""}`
                    : className,
            ...otherProps,
        };

        return href || disabled || !target ? (
            <Anchor
                {...props}
                as={disabled ? "span" : "a"}
                href={disabled ? undefined : href}
                ref={ref}
            />
        ) : enableActiveNav ? (
            <ReactNavLink
                {...props}
                customActiveNavPath={customActiveNavPath}
                ref={ref as ForwardedRef<HTMLAnchorElement>}
                to={target}
            />
        ) : (
            <ReactRouterLink
                {...props}
                ref={ref as ForwardedRef<HTMLAnchorElement>}
                to={target}
            />
        );
    },
);

SharedLink.displayName = "SharedLink";

const StyledSharedLink = styled(SharedLink)`
    ${styleLink}
`;

export type LinkContentProps = {
    readonly kind?: LinkKind;
    readonly icon?: ReactNode;
};

export const useLinkContent = ({
    children,
    icon,
}: LinkContentProps & {
    children?: ReactNode;
}): JSX.Element => (
    <>
        {children}
        {icon}
    </>
);

/** Link components styled as a link that wraps up a native anchor element and a React Router link. Can be used with either href or to prop. */
export const Link = forwardRef<
    HTMLAnchorElement | HTMLSpanElement,
    LinkContentProps &
        LinkElementOnlyProps &
        (NativeLinkProps | ReactLinkProps | ReactNavLinkProps)
>((props, ref) => {
    const {
        kind = defaultLinkKind,
        children,
        icon,
        disabled,
        ...otherProps
    } = props;

    const content = useLinkContent({ icon, children });

    return (
        <StyledSharedLink
            {...otherProps}
            $disabled={disabled ?? false}
            $kind={kind}
            disabled={disabled}
            ref={ref}
        >
            {content}
        </StyledSharedLink>
    );
});

Link.displayName = "Link";

const ButtonLinksKinds = {
    primary: styled(SharedLink)`
        ${buttonStyle}
        ${primaryStyle}
    `,
    secondary: styled(SharedLink)`
        ${buttonStyle}
        ${secondaryStyle}
    `,
    ghost: styled(SharedLink)`
        ${buttonStyle}
        ${ghostStyle}
    `,
    success: styled(SharedLink)`
        ${buttonStyle}
        ${successStyle}
    `,
    destructive: styled(SharedLink)`
        ${buttonStyle}
        ${destructiveStyle}
    `,
    warning: styled(SharedLink)`
        ${buttonStyle}
        ${warningStyle}
    `,
    "ghost-reverse": styled(SharedLink)`
        ${buttonStyle}
        ${ghostReverseStyle}
    `,
    "secondary-reverse": styled(SharedLink)`
        ${buttonStyle}
        ${secondaryReverseStyle}
    `,
    action: styled(SharedLink)`
        ${buttonStyle}
        ${actionStyle}
    `,
    dark: styled(SharedLink)`
        ${buttonStyle}
        ${darkStyle}
    `,
} as Record<
    ButtonKind,
    ForwardRefExoticComponent<
        ButtonStyleProps &
            LinkElementOnlyProps &
            Omit<
                NativeLinkProps | ReactLinkProps | ReactNavLinkProps,
                "prefix" | "suffix"
            > &
            RefAttributes<HTMLAnchorElement | HTMLSpanElement>
    >
>;

/** Link components styled as a button that wraps up a native anchor element and a React Router link. Can be used with either href or to prop. */
export const ButtonLink = forwardRef<
    HTMLAnchorElement | HTMLSpanElement,
    ButtonBaseProps &
        LinkElementOnlyProps &
        Omit<
            NativeLinkProps | ReactLinkProps | ReactNavLinkProps,
            "prefix" | "suffix"
        > & {
            readonly autoFocus?: boolean;
        }
>((props, ref): JSX.Element => {
    const {
        autoFocus,
        kind,
        disabled = false,
        loading = false,
        selected = false,
        size,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        children,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        prefix,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        suffix,
        icon,
        ...buttonAttributes
    } = props;

    const linkRef = useInternalRef<HTMLAnchorElement | HTMLSpanElement>(ref);

    const Component = ButtonLinksKinds[kind];

    const content = useButtonContent(props);

    useEffect(() => {
        // Anchor element does not handle autoFocus, therefor it's managed here.
        if (autoFocus) {
            linkRef.current?.focus();
        }
    }, [autoFocus, linkRef]);

    return (
        <Component
            {...buttonAttributes}
            $disabled={disabled || loading}
            $loading={loading}
            $selected={selected}
            $size={size}
            $square={Boolean(icon)}
            disabled={disabled || loading}
            ref={linkRef}
        >
            {content}
        </Component>
    );
});

ButtonLink.displayName = "ButtonLink";
