import type { ForwardedRef, MutableRefObject } from "react";
import { useEffect, useRef } from "react";

export const useInternalRef = <T>(
    ref: ForwardedRef<T>,
): MutableRefObject<T | null> => {
    const internalRef = useRef<T | null>(null);

    useEffect(() => {
        if (!ref) return;

        if (typeof ref === "function") {
            ref(internalRef.current);
            return;
        }

        ref.current = internalRef.current;
    }, [ref]);

    return internalRef;
};
