/* eslint-disable react/no-array-index-key */

import type { CSSProperties } from "react";

const SVG_VIEWBOX_SIZE = 286;
const SLICES_COUNT = 12;

interface Slice {
    text: string;
    textStyle: Pick<
        CSSProperties,
        "color" | "fontFamily" | "fontSize" | "fontWeight"
    >;
    backgroundColor: string;
}

export interface Props {
    readonly slices: Slice[];
    readonly flapperColor?: string;
    readonly className?: string;
}

// Without DIAMETER_CORRECTION correction, triangle will appear a little bit too small
const DIAMETER_CORRECTION = 5;

// We must process triangle vertices according to opposite angle. Applying trigonometry : tan(alpha [in RAD]) = Opposite / Hypotenuse
// After equation resolution, the result is :
const BORDER_WIDTH =
    Math.tan(((360 / (SLICES_COUNT * 2)) * Math.PI) / 180) *
    (SVG_VIEWBOX_SIZE / 2 + DIAMETER_CORRECTION);

export const StaticWheel = ({
    className,
    slices,
    flapperColor,
}: Props): JSX.Element => {
    if (slices.length !== SLICES_COUNT) {
        throw new Error("Wheel is implemented for exactly 12 slices");
    }

    return (
        <svg
            overflow="visible"
            style={{ overflow: "visible" }}
            viewBox={`0 0 ${SVG_VIEWBOX_SIZE} ${SVG_VIEWBOX_SIZE}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height={SVG_VIEWBOX_SIZE + 40}
                    id="filter0_d"
                    width={SVG_VIEWBOX_SIZE + 40}
                    x="-20"
                    y="-20"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        in2="BackgroundImageFix"
                        mode="normal"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        mode="normal"
                        result="shape"
                    />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height={SVG_VIEWBOX_SIZE + 40}
                    id="filter1_d"
                    width={SVG_VIEWBOX_SIZE + 40}
                    x="-20"
                    y="-20"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
                    />
                    <feBlend
                        in2="BackgroundImageFix"
                        mode="normal"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        mode="normal"
                        result="shape"
                    />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height={SVG_VIEWBOX_SIZE + 40}
                    id="filter2_d"
                    width={SVG_VIEWBOX_SIZE + 40}
                    x="-20"
                    y="-20"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        in2="BackgroundImageFix"
                        mode="normal"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        mode="normal"
                        result="shape"
                    />
                </filter>
                <clipPath id="circle">
                    <circle
                        cx={SVG_VIEWBOX_SIZE / 2}
                        cy={SVG_VIEWBOX_SIZE / 2}
                        r={SVG_VIEWBOX_SIZE / 2 - 12}
                    />
                </clipPath>
            </defs>
            <circle
                cx={SVG_VIEWBOX_SIZE / 2}
                cy={SVG_VIEWBOX_SIZE / 2}
                fill="white"
                filter="url(#filter0_d)"
                r={SVG_VIEWBOX_SIZE / 2}
            />
            <g className={className}>
                {slices.map((slice, index) => (
                    <g
                        clipPath="url(#circle)"
                        key={index}
                        transform={`rotate(${
                            (360 / SLICES_COUNT) * index +
                            360 / (SLICES_COUNT * 2)
                        }, ${SVG_VIEWBOX_SIZE / 2}, ${SVG_VIEWBOX_SIZE / 2})`}
                    >
                        <polygon
                            fill={slice.backgroundColor}
                            key={index}
                            points={`${SVG_VIEWBOX_SIZE / 2},${
                                SVG_VIEWBOX_SIZE / 2
                            } ${SVG_VIEWBOX_SIZE / 2 - BORDER_WIDTH},0 ${
                                SVG_VIEWBOX_SIZE / 2 + BORDER_WIDTH
                            },0`}
                        />
                        <text
                            fill="currentColor"
                            fontSize={13}
                            fontWeight={600}
                            style={slice.textStyle}
                            transform={`rotate(90, ${SVG_VIEWBOX_SIZE / 2}, 0)`}
                            x={SVG_VIEWBOX_SIZE / 2 + 25}
                            y={5}
                        >
                            {slice.text}
                        </text>
                    </g>
                ))}
            </g>
            <circle
                cx={SVG_VIEWBOX_SIZE / 2}
                cy={SVG_VIEWBOX_SIZE / 2}
                fill="white"
                filter="url(#filter1_d)"
                r="23"
            />
            {typeof flapperColor === "string" && (
                <g
                    fill={flapperColor}
                    filter="url(#filter2_d)"
                    transform={`rotate(-90, ${SVG_VIEWBOX_SIZE / 2}, ${
                        SVG_VIEWBOX_SIZE / 2
                    }) translate(0, -20)`}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M143 36L156.856 9H129.144L143 36Z" />
                    <path
                        d="M143 36L156.856 9H129.144L143 36Z"
                        stroke="white"
                        strokeWidth="5"
                    />
                </g>
            )}
        </svg>
    );
};
