import { useCallback, useMemo } from "react";

import { useCurrentWorkspace } from "@/Support/Hooks/useCurrentWorkspace";
import { useMe } from "@/Support/Requests/useMe";

type UseGTMReturn = {
    setupGTMLayer: () => boolean;
};

/** GoogleTagManager hook */
export const useGoogleTagManager = (): UseGTMReturn => {
    const currentWorkspace = useCurrentWorkspace();

    const { data: me } = useMe();

    /** Initializes dataLayer attributes in window. User needs to be logged-in. */
    const setupGTMLayer = useCallback(() => {
        if (typeof me === "undefined") {
            console.error(
                "GoogleTagManager can only be setup when user is logged-in.",
            );
            return false;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            user: {
                id: me.id,
                firstName: me.name,
                role: currentWorkspace?.role,
                creationDate: me.created_at,
            },
            company: {
                name: currentWorkspace?.workspace.company_name,
            },
        });
        return true;
    }, [me, currentWorkspace?.role, currentWorkspace?.workspace.company_name]);

    return useMemo(() => ({ setupGTMLayer }), [setupGTMLayer]);
};
