import { useIntl } from "react-intl";

import { LoadingTemplate } from "./Components/LoadingComponents";

const Loading = ({ title }: { readonly title?: string }): JSX.Element => {
    const intl = useIntl();

    return (
        <LoadingTemplate
            title={
                title ??
                intl.formatMessage({
                    id: "loading.title",
                    defaultMessage: "Loading...",
                    description: "Loading page title",
                })
            }
        />
    );
};

export default Loading;
