import { useCallback /*, useMemo*/, useContext } from "react";
// import type { IntlShape } from "react-intl";
import { useIntl } from "react-intl";

import { useRequestCodeText } from "@/Support/Hooks/useRequestCodeText";
import type { RequestError } from "@/Support/Requests/graphQLClient";

import { useToasts } from "@/Components/Toast";

import Context from "@/Pages/Context";

// TODO: rework to descriptors dictionary when intl extract supports non-inlined descriptors
/* const getOnErrorToastMessages = (intl: IntlShape): Record<string, string> => ({
    example1: intl.formatMessage({
        id: "request.message.example1",
        description:
            "Request message toast: Example 1 text",
        defaultMessage: "Example.",
    }),
    example2: intl.formatMessage({
        id: "request.message.example2",
        description:
            "Request message toast: Example 2 text",
        defaultMessage: "Example.",
    }),
});
*/

type useReactQueryOnErrorReturn = (err: unknown) => void;

/** Generic onError callback for react-query queries/mutations */
export const useReactQueryOnError = (
    type: "mutation" | "query",
): useReactQueryOnErrorReturn => {
    const { setMaintenanceMode, maintenanceMode } = useContext(Context);

    const intl = useIntl();

    const { createToast } = useToasts();

    const requestCodeText = useRequestCodeText();

    // const messages = useMemo(() => getOnErrorToastMessages(intl), [intl]);

    return useCallback<useReactQueryOnErrorReturn>(
        (err) => {
            const data = err as RequestError;

            const isInMaintenance = data.code === 517;

            // We update maintenance value according its current state.
            // If maintenance is active and a retried query succeed, we remove maintenance mode.
            if (isInMaintenance !== maintenanceMode) {
                setMaintenanceMode(true);
            }
            // If maintenance mode is enabled, no further action is being taken when a query/mutation fails.
            if (isInMaintenance) {
                return;
            }

            // We only display toasts for mutations as queries feedbacks is usually handled in the UI.
            if (type === "query") return;

            // Priority:
            // 1- If validation errors we do not display any toast
            // 2- If message is defined we display it in a toast
            // 3- Else we transform status code to text and display it in a toast
            if (Object.keys(data.validation).length > 0) return;
            if (data.message) {
                createToast("error", data.message);

                /*
                const error = messages[data.message];
                if (error) {
                    createToast("error", error);
                } else {
                    console.error(`"${data.message}" is not a valid message key, toast message cannot be displayed.`);
                }
                */
            } else {
                createToast(
                    "error",
                    intl.formatMessage(
                        {
                            id: "request.failure.generic",
                            description:
                                "Common text displayed inside a Toast when a request fails and there is no available message",
                            defaultMessage:
                                "Oh oh! Something went wrong ({error})",
                        },
                        {
                            error: requestCodeText(data.code) || "?",
                        },
                    ),
                );
            }
        },
        [
            type,
            maintenanceMode,
            requestCodeText,
            setMaintenanceMode,
            createToast,
            intl /*, messages */,
        ],
    );
};
