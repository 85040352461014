/* eslint-disable @typescript-eslint/no-empty-function */

import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

export interface AppContext {
    maintenanceMode: boolean;
    setMaintenanceMode: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<AppContext>({} as AppContext);

export default Context;
