export type PageviewsCount = number;

export enum BillingIntervalEnum {
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
}

export enum BillingStatusEnum {
    NONE = "NONE",
    PAID = "PAID",
    PENDING = "PENDING",
    FAILED = "FAILED",
    EXPIRED = "EXPIRED",
    INVALID = "INVALID",
    CANCELLED = "CANCELLED",
    REQUIRES_AUTHENTICATION = "REQUIRES_AUTHENTICATION",
    REQUIRES_ACTION = "REQUIRES_ACTION",
}

export const billingIntervalMap = {
    monthly: BillingIntervalEnum.MONTHLY,
    yearly: BillingIntervalEnum.YEARLY,
} as const;

export const billingIntervalReverseMap = {
    [BillingIntervalEnum.MONTHLY]: "monthly",
    [BillingIntervalEnum.YEARLY]: "yearly",
} as const;

export type BillingInterval = BillingIntervalEnum;
