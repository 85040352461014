/** We either mock the API when VITE_API_MOCK_ENABLED is set to "true" or when running code through vitest package.*/
const isMocking =
    import.meta.env.VITE_API_MOCK_ENABLED === "true" ||
    import.meta.env.VITEST === "true";

/** If mocking is enabled, url will target worker service. */
export const restUrl = isMocking ? import.meta.env.VITE_API_MOCK_URL : "/api";

/** Rest API endpoints */
export const restEndpoint = {
    requestLogout: `${restUrl}/account/logout`,
    redirectPostSetup: `${restUrl}/redirect/setup`,
    redirectW1PostSetup: `${restUrl}/redirect/setup/wisepops`,
    redirectWispPostSetup: `${restUrl}/redirect/setup/wisp`,
    redirectDashboard: `${restUrl}/redirect/home`,
    redirectShopifySetupCode: `${restUrl}/shopify/install/:website/wisepops`,
} as const;
