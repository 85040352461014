import styled from "styled-components";

import { Text } from "@/Components/Text";

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: auto;
    max-width: 500px;
    padding: 60px 80px;
    text-align: center;
`;

export const Title = styled(Text.H3)`
    color: var(--color-neutral-700);
    font-weight: 500;
`;

export const Description = styled(Text.Body)`
    color: var(--color-neutral-500);
    max-width: 360px;
`;
