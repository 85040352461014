import styled, { css } from "styled-components";

const styleH0 = css`
    font-size: var(--size-h0);
    font-weight: 700;
    line-height: var(--height-h0);
`;

const H0 = styled.h1`
    ${styleH0}
`;
const styleH1 = css`
    font-size: var(--size-h1);
    line-height: var(--height-h1);
`;

const H1 = styled.h1`
    ${styleH1}
`;

const styleH2 = css`
    font-size: var(--size-h2);
    line-height: var(--height-h2);
`;

const H2 = styled.h2`
    ${styleH2}
`;

const styleH3 = css`
    font-size: var(--size-h3);
    line-height: var(--height-h3);
`;

const H3 = styled.h3`
    ${styleH3}
`;

const styleBodyL = css`
    font-size: var(--size-body-l);
    line-height: var(--height-body-l);
`;

const BodyL = styled.span`
    ${styleBodyL}
`;

const styleBody = css`
    font-size: var(--size-body);
    line-height: var(--height-body);
`;

const Body = styled.span`
    ${styleBody}
`;

const styleCaption = css`
    font-size: var(--size-caption);
    line-height: var(--height-caption);
`;

const Caption = styled.span`
    ${styleCaption}
`;

export const Text = {
    H0,
    H1,
    H2,
    H3,
    BodyL,
    Body,
    Caption,
    style: {
        H1: styleH1,
        H2: styleH2,
        H3: styleH3,
        BodyL: styleBodyL,
        Body: styleBody,
        Caption: styleCaption,
    },
};
