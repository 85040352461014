import { useRef, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useCurrentWorkspace } from "@/Support/Hooks/useCurrentWorkspace";
import { useSegment } from "@/Support/Hooks/useSegment";
import { useBilling } from "@/Support/Requests/useBilling";
import { useMe } from "@/Support/Requests/useMe";

import { BillingIntervalEnum } from "@/Pages/Billing/types";
import { UserRole } from "@/Pages/types";

export const useWisepops = (): void => {
    const location = useLocation();

    const segment = useSegment();

    const [searchParams, setSearchParams] = useSearchParams();

    const wisepopsInitRef = useRef(false);

    const currentWorkspace = useCurrentWorkspace();

    const isEditor = currentWorkspace?.role === UserRole.EDITOR;

    const { isSuccess: isBillingSuccess, data: billingData } = useBilling(
        { workspace: currentWorkspace?.workspace.id ?? 0 },
        {
            enabled:
                typeof currentWorkspace?.workspace.id === "number" && !isEditor,
        },
    );

    const {
        isError: isMeError,
        isSuccess: isMeSuccess,
        data: meData,
    } = useMe();

    useEffect(
        () => {
            if (isMeError) {
                if (!wisepopsInitRef.current) {
                    wisepopsInitRef.current = true;
                    window.initWisp?.();
                }
                if (
                    searchParams.get("after_logout") === "true" &&
                    location.pathname === "/login"
                ) {
                    segment.reset();
                    setSearchParams(
                        (prev) => {
                            prev.delete("after_logout");
                            return prev;
                        },
                        { replace: true },
                    );
                }
                return;
            }
            if (wisepopsInitRef.current) return;
            if (isMeSuccess && (isEditor || isBillingSuccess)) {
                wisepopsInitRef.current = true;
                segment.identify(meData);

                const planPriceMonth =
                    billingData?.billingInterval === BillingIntervalEnum.YEARLY
                        ? billingData.planPrice / 12
                        : billingData?.planPrice;

                window.initWisp?.({
                    planPriceMonth,
                    custom: {
                        userRole: meData.workspaces.items[0]?.role,
                    },
                });
                window.wisepops?.(
                    "properties",
                    {
                        user: {
                            w2Id: meData.id,
                            firstName: meData.name,
                            role:
                                meData.workspaces.items[0]?.role ??
                                UserRole.EDITOR,
                            creationDate: meData.created_at,
                        },
                        company: {
                            planPriceMonth,
                        },
                        workspace: {
                            w2Id: meData.workspaces.items[0]?.workspace.id,
                            name: meData.workspaces.items[0]?.workspace
                                .company_name,
                            creationDate:
                                meData.workspaces.items[0]?.workspace
                                    .created_at,
                            popupsAndNotifs: [
                                8227, 9811, 30368, 30486, 30994, 32896, 35937,
                                36569, 36746, 36985, 37115, 37257, 37303, 38469,
                                40768, 40986, 41182, 41195, 41366, 41425, 41469,
                                41485, 41632, 41666, 42032, 42069, 42209, 42383,
                                42638, 42716, 42992, 43012, 43039, 43276, 43420,
                                44735, 45167, 45224, 45594, 46205, 46861, 46984,
                                47151, 47155, 47533, 47608, 47764, 47906, 48296,
                                48537, 48773, 49157, 49408, 49653, 49877, 49937,
                                49955, 50190, 50255, 50742, 50911, 51364, 51442,
                                51549, 51649, 52073, 52087, 52099, 52218, 52306,
                                52988, 53019, 53165, 53372, 53453, 53545, 53560,
                                53772, 53898, 53927, 54151, 54317, 54503, 54568,
                                54678, 54796, 54841, 55070, 55194, 55373, 55463,
                                55538, 55617, 55624, 55639, 55791, 55793, 55866,
                                55927, 56003, 56050, 56106, 56139, 56199, 56322,
                                56423, 56457, 56539, 56879, 56908, 56927, 56974,
                                57260, 57267, 57643, 57753, 57844, 57968, 58116,
                                58847, 58879, 59035, 59051, 59162, 59375, 59484,
                                59544, 59669, 59827, 59988, 60003, 60058, 60080,
                                60091, 60114, 60128, 60226, 60270, 60296, 60344,
                                60386, 60429, 60470, 60684, 60903, 60904, 60932,
                                61132, 61211, 61221, 61226, 61246, 61307, 61435,
                                61643, 61766, 61815, 61843, 61869, 61957, 62009,
                                62048, 62252, 62584, 62607, 62632, 62791, 62801,
                                62823, 62935, 63014, 63030, 63137, 63195, 63402,
                                63507, 63515, 63578, 63599, 63634, 63687, 63704,
                                63755, 63874, 63977, 64055, 64145, 64245, 64317,
                                64358, 64367, 64426, 64856, 65034, 65077, 65103,
                                65139, 65243, 65261, 65316, 31539137, 31539309,
                                31539419, 31539452, 31539503, 31539880,
                                31540094, 31540166, 31540778, 31540804,
                                31540922, 31541170, 31541175, 31541215,
                                31541850, 31542193, 31542402, 31542433,
                                31542931, 31543500, 31543602, 31543871,
                                31544027, 31544033, 31544151, 31544228,
                                31544269, 31544273, 31544334, 31544461,
                                31544477,
                            ].includes(
                                meData.workspaces.items[0]?.workspace.id,
                            ),
                        },
                    },
                    { temporary: true },
                );
            }
        },
        /* eslint-disable-line react-hooks/exhaustive-deps */ [
            isBillingSuccess,
            isMeError,
            isBillingSuccess,
        ],
    );
};
