import { useCallback, useContext } from "react";

import Context from "@/Pages/Context";

/** Generic onSuccess callback for react-query queries/mutation */
export const useReactQueryOnSuccess = (): (() => void) => {
    const { setMaintenanceMode, maintenanceMode } = useContext(Context);

    return useCallback(() => {
        if (maintenanceMode) {
            setMaintenanceMode(false);
        }
    }, [maintenanceMode, setMaintenanceMode]);
};
